import { useEffect, useState } from 'react'
import { Form, Row, Col, Button, Tabs, Tab, Table } from 'react-bootstrap'
import { DTOAvisoArribo } from '../../../DTO/Clientes/DTOAvisoArribo'
import AvisosArribosService from '../../../Services/Clientes/AvisosArribos.Service'
import { AxiosError } from 'axios'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import AvisosFileManager from '../../Utils/AvisosFileManager/AvisosFileManager'
import { ICatPlantas } from '../../../Interfaces/Catalogos/ICatPlantas'
import CatPlantasService from '../../../Services/Catalogos/CatPlantas.Service'
import { DTOPlantasAsignadas } from '../../../DTO/DTOPlantasAsignadas'
import CorresponsalesCatAduanasServices from '../../../Services/Corresponsalias/Corresponsales.CatAduanas.Services'
import ICorresponsalCatAduanas from '../../../Interfaces/Corresponsales/ICorresponsalCatAduanas'
import AvisosFileManagerServices from '../../../Services/Utils/AvisosFileManager.Services'
import IAvisosFileManager from '../../../Interfaces/Utils/IAvisosFileManager'
import '../../../css/generic01.css'
import { AvisosMFileManager } from '../../Utils/AvisosFileManager/AvisosMFileManager'
import { IAvisoArriboCatDocumentos } from '../../../Interfaces/Catalogos/IAvisoArriboCatDocumentos'
import { IAvisoArribo } from '../../../Interfaces/Catalogos/AvisosArribos/IAvisoArribo'
import { error } from 'console'
import ICatCorresponsales from '../../../Interfaces/Catalogos/ICatCorresponsales'
import CorresponsalesServices from '../../../Services/Catalogos/Corresponsales.Services'
interface IProps {
    idAviso: number,
    UserId: number,
    Perfil: string
}

export const AvisosArribosForm = ({idAviso, UserId, Perfil} : IProps) => {
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  const [validated, setValidated] = useState(false);
  const [plantas, setPlantas] = useState<DTOPlantasAsignadas[]>([])
  const [id, setId] = useState(idAviso)
  const [ordenCompra, setOrdenCompra] = useState('')
  const [factura, setFactura] = useState('')
  const [incoterm, setIncoterm] = useState('')
  const [contacto, setContacto] = useState('')
  const [idAduana, setIdAduana] = useState(0)
  const [aduana, setAduana] = useState('')
  const [informacionTecnica, setInformacionTecnica] = useState('')
  const [instruccionesEntrega, setInstruccionesEntrega] = useState('')
  const [guiaEnvio, setGuiaEnvio] = useState('')
  const [fechaETA, setFechaETA] = useState<string | undefined>()
  const [solicitudPedido, setSolicitudPedido] = useState('')
  const [hazmat, setHazmat] = useState(false)
  const [perecedero, setPerecedero] = useState(false)
  const [idPlanta, setIdPlanta] = useState(0)
  const [planta, setPlanta] = useState('')
  const [aduanas, setAduanas] = useState<ICorresponsalCatAduanas[]>([])
  const [Documentos, setDocumentos] = useState<IAvisoArriboCatDocumentos[]>([])
  const [comentarios, setComentarios] = useState('')
  const [idFactura, setIdFactura] = useState(0)
  const [idGuia, setIdGuia] = useState(0)
  const [idFicha, setIdFicha] = useState(0)
  const [idPackingList, setIdPackingList] = useState(0)
  const [idHojaSeguridad, setIdHojaSeguridad] = useState(0)
  const [idCorresponsal, setIdCorresponsal] = useState(0)
  const [corresponsales, setCorresponsales] = useState<ICatCorresponsales[]>([])
  const [idTrafico, setIdTrafico] = useState(0)
    useEffect(() => {
        if(id > 0){
            AvisosArribosService.getById(id)
            .then(resp => {
                setOrdenCompra(resp.data.ordenCompra)
                setFactura(resp.data.factura)
                setIncoterm(resp.data.incoterm)
                setContacto(resp.data.contacto)
                setIdAduana(resp.data.idAduana)
                setAduana(resp.data.aduana)
                setInformacionTecnica(resp.data.informacionTecnica)
                setInstruccionesEntrega(resp.data.instruccionesEntrega)
                setGuiaEnvio(resp.data.guiaEnvio)
                setFechaETA(setDate(resp.data.fechaETA))
                setSolicitudPedido(resp.data.solicitudPedido)
                setHazmat(resp.data.hazmat)
                setPerecedero(resp.data.perecedero)
                setIdPlanta(resp.data.idPlanta)
                setPlanta(resp.data.planta)
                setComentarios(resp.data.comentarios)
                setIdFactura(resp.data.idFactura)
                setIdGuia(resp.data.idGuia)
                setIdFicha(resp.data.idFicha)
                setIdPackingList(resp.data.idPackingList)
                setIdHojaSeguridad(resp.data.idHojaSeguridad)
                setIdCorresponsal(resp.data.idCorresponsal)
                setIdTrafico(resp.data.idTrafico)
                /* CorresponsalesServices.getByAduana(resp.data.agenteAduanal)
                .then(response => setCorresponsales(response.data))
                .catch((error: AxiosError) => {
                  setHeader('Error')
                  setMsg(error.request.responseText)
                  setShowMsg(true)
                }) */
            })
            .catch((error: AxiosError) => {
              setHeader('Error')
              setMsg(error.request.responseText)
              setShowMsg(true)
            })
        }
        CatPlantasService.Asignadas(UserId)
        .then(resp => {
          setPlantas(resp.data)
        })
        .catch((error: AxiosError) => {
          setHeader('Error')
          setMsg(error.request.responseText)
          setShowMsg(true)
        })
        CorresponsalesCatAduanasServices.getByUsuarioId(UserId)
        .then(resp => setAduanas(resp.data))
        .catch((error: AxiosError) => {
          setHeader('Error')
          setMsg(error.request.responseText)
          setShowMsg(true)
        })
        CorresponsalesServices.getAll()
        .then(resp => setCorresponsales(resp.data))
        .catch((error: AxiosError) => {
          setHeader('Error')
          setMsg(error.request.responseText)
          setShowMsg(true)
        })
        getDocumentos(idAviso)
    },[])

    function setDate(fecha: string | undefined): string {
      if (!fecha) return ''
      else {
        var dd = fecha?.substring(3, 5)
        var mm = fecha?.substring(0, 2)
        var yyyy = fecha?.substring(6, 10)
        return yyyy + '-' + mm + '-' + dd
      }
    }

    const getDocumentos = (id: number) => {
      AvisosFileManagerServices.GetAvisoFiles(id)
      .then(resp => {
        setDocumentos(resp.data)
      })
      .catch((error: AxiosError) => console.log(error))
    }
    
    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if(form.checkValidity() === true){
          let data: DTOAvisoArribo = {
            id,
            idUsuario: UserId,
            ordenCompra,
            factura,
            incoterm,
            informacionTecnica,
            instruccionesEntrega,
            contacto,
            idAduana,
            guiaEnvio,
            fechaETA,
            solicitudPedido,
            hazmat,
            perecedero,
            idPlanta,
            planta,
            comentarios,
            idCorresponsal,
            aduana: '',
            idFactura: 0,
            idGuia: 0,
            idFicha: 0,
            idPackingList: 0,
            idHojaSeguridad: 0,
            idTrafico
          }
            AvisosArribosService.append(data)
            .then(resp => {
              setId(resp.data.id)
              setHeader('Informativo')
              setMsg('Información guardada correctamente')
              setShowMsg(true)
            })
            .catch((error: AxiosError) => {
              setHeader('Error')
              setMsg(error.request.responseText)
              setShowMsg(true)
            })
        }
        setValidated(true);
      }

    const handlePlantaChange = (e:any) => {
      let planta = plantas.filter(p => p.id == e.target.value)[0]
      setIdPlanta(planta.id)
      setContacto(planta.contacto)
      setInstruccionesEntrega(planta.direccion)
    }

    const handleHiddenFormControlRendering = (proceso: number) => {
      if(proceso === 48)
        return( <Form.Control value={idFactura} type='number' min={1} hidden required></Form.Control> )
      if(proceso === 49)
        return( <Form.Control value={idGuia} type='number' min={1} hidden required></Form.Control> )
      if(proceso === 50)
        return (<Form.Control value={idFicha} type='number' min={1} hidden required></Form.Control>)
      if(proceso === 51)
        return (<Form.Control value={idPackingList} type='number' min={1} hidden required></Form.Control>)
      if(proceso === 53)
        return (<Form.Control value={idHojaSeguridad} type='number' min={hazmat ? 1 : 0} hidden required={hazmat}></Form.Control>)
      return ("")
    }

    const handleDocumentAppend = (id: number, proceso: number) => {
      if(proceso === 48)
        setIdFactura(id)
      if(proceso === 49)
        setIdGuia(id)
      if(proceso === 50)
        setIdFicha(id)
      if(proceso === 51)
        setIdPackingList(id)
      if(proceso === 53)
        setIdHojaSeguridad(id)
    }
    
    const nuevaReferencia = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      const form = event.currentTarget;
      if(form.checkValidity() === true){
        AvisosArribosService.createReferencia(id, idCorresponsal)
        .then((resp) => {
          setHeader('Informativo')
          setMsg('Referencia ' + resp.data.folioGemco + ' creada con éxito')
          setShowMsg(true)
          return;
        })
        .catch((error: any) => {
          setHeader('Error')
          setMsg(error.response.data.mensaje)
          setShowMsg(true)
          return;
        })
      }else{
        setHeader('Error')
          setMsg('Falta información para generar la referencia')
          setShowMsg(true)
      }
    }

    return (
      <div style={{height:'500px', overflowY: 'scroll', padding:'10px 20px 0 20px'}}>
          <Form noValidate validated={validated} onSubmit={Perfil === 'Clientes' ? handleSubmit : nuevaReferencia}>
            <Row className='justify-content-end'>
              <Col xs={3} style={{textAlign:'end'}}>
                <Form.Check type='checkbox' label='¿Peligroso?' inline checked={hazmat} onChange={(e) => {setHazmat(e.target.checked)}} disabled={Perfil !== 'Clientes'}/>
                <Form.Check type='checkbox' label='¿Perecedero?' inline checked={perecedero} onChange={(e) => {setPerecedero(e.target.checked)}} disabled={Perfil !== 'Clientes'}/>
              </Col>
            </Row>
            <Row>
              <Form.Label column xs={4}>
                Guia
                <Form.Control type='text' required value={guiaEnvio} onChange={(e) => setGuiaEnvio(e.target.value)} disabled={Perfil !== 'Clientes'}/>
                <Form.Control.Feedback type="invalid">Debe capturar la guía para guardar</Form.Control.Feedback>
              </Form.Label>
              <Form.Label column xs={4}>
                Orden de compra
                <Form.Control type='text' value={ordenCompra} onChange={(e) => setOrdenCompra(e.target.value)} disabled={Perfil !== 'Clientes'}/>
              </Form.Label>
              <Form.Label column xs={4}>
                Factura
                <Form.Control type='text' value={factura} onChange={(e) => setFactura(e.target.value)} disabled={Perfil !== 'Clientes'}/>
              </Form.Label>
            </Row>
            <Row>
              <Form.Label column xs={Perfil === 'Clientes' ? 4 : 3}>
                Aduana
                { Perfil === 'Clientes' ? <>
                <Form.Control
                  as='select'
                  className='form-select'
                  value={idAduana} 
                  onChange={(e) => setIdAduana(+e.target.value)}
                  disabled={Perfil !== 'Clientes'}
                  required
                >
                  <option value={''} key={0}>- SELECCIONE -</option>
                  {
                    aduanas ? aduanas.map(a => (
                      <option key={a.id} value={a.id}>{a.nombre}</option>
                    )) : ''
                  }
                </Form.Control>
                <Form.Control.Feedback type="invalid">Debe seleccionar una aduana para guardar</Form.Control.Feedback>
                </>
                :
                <Form.Control type='text' value={aduana} disabled/>
              }
              </Form.Label>
              { Perfil === 'Corresponsalias' ? <>
                  <Form.Label column xs={4}>
                    Corresponsal  
                    <Form.Control
                      as='select'
                      className='form-select'
                      value={idCorresponsal} 
                      onChange={(e) => setIdCorresponsal(+e.target.value)}
                      disabled={Perfil !== 'Corresponsalias'}
                      required
                    >
                      <option value={''} key={0}>- SELECCIONE -</option>
                      {
                        corresponsales ? corresponsales.map(c => (
                          <option key={c.id} value={c.id}>{c.nombre} | {c.aduana} | {c.patente}</option>
                        )) : ''
                      }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">Debe seleccionar un corresponsal para guardar</Form.Control.Feedback>
                  </Form.Label>
                </>
                : ''
              }
              <Form.Label column xs={Perfil === 'Clientes' ? 4 : 2}>
                Planta
                { Perfil === 'Clientes' ? <>
                  <Form.Control as='select' className='form-select' value={idPlanta} onChange={handlePlantaChange} required>
                    <option key={0} value=''>- SELECCIONE -</option>
                    {
                      plantas ? plantas.filter(p => p.asignada == true).map(p => (
                        <option key={p.id} value={p.id}>{p.nombre}</option>
                      )) : ''
                    }
                  </Form.Control> 
                  <Form.Control.Feedback type="invalid">Debe seleccionar una planta para guardar</Form.Control.Feedback>
                  </>
                  :
                  <Form.Control type='text' value={planta} disabled/>
                }
              </Form.Label>
              <Form.Label column xs={Perfil === 'Clientes' ? 4 : 3}>
                Instrucciones de entrega
                <Form.Control type='text' value={instruccionesEntrega} onChange={(e) => setInstruccionesEntrega(e.target.value)} disabled={Perfil !== 'Clientes'} required/>
                <Form.Control.Feedback type="invalid">Debe capturar las instrucciones de entrega para guardar</Form.Control.Feedback>
              </Form.Label>
            </Row>
            <Row>
              <Form.Label column xs={6}>
                Contacto
                <Form.Control type='text' value={contacto} onChange={(e) => setContacto(e.target.value)} disabled={Perfil !== 'Clientes'} required/>
                <Form.Control.Feedback type="invalid">Debe capturar el contacto para guardar</Form.Control.Feedback>
              </Form.Label>
              <Form.Label column xs={2}>
                Incoterm
                <Form.Control type='text' value={incoterm} onChange={(e) => setIncoterm(e.target.value)} disabled={Perfil !== 'Clientes'} required/>
                <Form.Control.Feedback type="invalid">Debe capturar el incoterm para guardar</Form.Control.Feedback>
              </Form.Label>
              <Form.Label column xs={2}>
                Fecha ETA
                <Form.Control type='date' value={fechaETA} onChange={(e) => setFechaETA(e.target.value)} disabled={Perfil !== 'Clientes'} required/>
                <Form.Control.Feedback type="invalid">Debe capturar fecha estimada de arribo para guardar</Form.Control.Feedback>
              </Form.Label>
              <Form.Label column xs={2}>
                SP Agente Aduanal
                <Form.Control type='text' value={solicitudPedido} onChange={(e) => setSolicitudPedido(e.target.value)} disabled={Perfil !== 'Clientes'} required/>
                <Form.Control.Feedback type="invalid">Debe capturar la solicitud de pedido para guardar</Form.Control.Feedback>
              </Form.Label>
            </Row>
            <Row>
              <Form.Label column xs={6}>
                Información técnica
                <Form.Control as='textarea' rows={4} value={informacionTecnica} onChange={(e) => setInformacionTecnica(e.target.value)} disabled={Perfil !== 'Clientes'}/>
              </Form.Label>
              <Form.Label column xs={6}>
                Comentarios
                <Form.Control as='textarea' rows={4} value={comentarios} onChange={(e) => setComentarios(e.target.value)} disabled={Perfil !== 'Clientes'}/>
              </Form.Label>
            </Row>
            { /* Perfil === 'Clientes' && */ id > 0?
              <>
                <Row>
                  {
                    Documentos ? Documentos.map(doc => {
                      if(doc.idRutaArchivo !== 54 && doc.idRutaArchivo !== 55){
                        return (
                        <Col xs={4}>
                          {doc.documento}
                          { handleHiddenFormControlRendering(doc.idRutaArchivo) }
                          <AvisosFileManager 
                            IDProcess={doc.idRutaArchivo} 
                            IDUser={UserId} 
                            IdFile={id} 
                            FileName={''} 
                            Leyenda={'Seleccione el PDF'} 
                            canDelete={Perfil === 'Clientes' ? true : false} 
                            FileType={['pdf', 'PDF']} 
                            width={'100%'} 
                            height={100} 
                            onAppendFM={function (idFile: number): void {
                              handleDocumentAppend(idFile, doc.idRutaArchivo)
                            } }
                            onDelete={function (idFile: number): void {
                              handleDocumentAppend(idFile, doc.idRutaArchivo)
                            }}
                          />
                          <Form.Control.Feedback type='invalid'>Debe subir este documento para guardar</Form.Control.Feedback>
                        </Col>
                        )
                      }
                    }) : ''
                  } 
                </Row> 
                <Row className='mt-2'>
                  <Col xs={6}>
                    <AvisosMFileManager 
                      idAviso={id} 
                      proceso={55} 
                      UserId={UserId} 
                      leyenda='Seleccione las imagenes del previo'
                      accept='image/*'
                      canDelete={Perfil === 'Clientes'}
                    />
                  </Col>
                  <Col xs={6}>
                    <AvisosMFileManager 
                      idAviso={id} 
                      proceso={54} 
                      UserId={UserId} 
                      leyenda='Seleccione los archivos adicionales'
                      accept=''
                      canDelete={Perfil === 'Clientes'}
                    />
                  </Col>
                </Row>
              </>
              : ''
            }
            <br/>
            <Row className={Perfil === 'Clientes' ? 'justify-content-end' : 'd-none'}>
              <Col xs={2} style={{textAlign:'right'}}>
                <Button type='submit'>Guardar</Button>
              </Col>
            </Row>
            <Row className={Perfil === 'Corresponsalias' ? 'justify-content-end' : 'd-none'}>
              <Col xs={2} style={{textAlign:'right'}}>
                <Button type='submit'>Crear Referencia</Button>
              </Col>
            </Row>
          </Form>
        <MsgInformativo
          show={show}
          msg={msg}
          header={header}
          msgColor={msgColor}
          closeToast={() => {
            setShowMsg(false)
          }}
        />
      </div>
  )
}
