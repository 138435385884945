import { RptAvisosArribos } from '../../DTO/Clientes/AvisosArribos/RptAvisosArribos';
import { DTOAvisoArribo } from '../../DTO/Clientes/DTOAvisoArribo';
import { IAvisoArribo } from '../../Interfaces/Catalogos/AvisosArribos/IAvisoArribo';
import ICorresponsalTrafico from '../../Interfaces/Corresponsales/ICorresponsalTrafico';
import http from '../common/http-common';

class AvisosArribosService {
    append(data: DTOAvisoArribo){
        return http.post<DTOAvisoArribo>(`AvisosArribos/`, data);
    }
    get(IdUsuario: number){
        return http.get<RptAvisosArribos[]>(`AvisosArribos?IdUsuario=${IdUsuario}`);
    }

    getById(id: number){
        return http.get<DTOAvisoArribo>(`AvisosArribos/GetById/${id}`);
    }

    createReferencia(idAviso: number, IdCorresponsal: number){
        return http.post<ICorresponsalTrafico>(`AvisosArribos/CreateReferencia?id=${idAviso}&IdCorresponsal=${IdCorresponsal}`);
    }
}

export default new AvisosArribosService();